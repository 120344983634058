import React from "react";
import PropTypes from "prop-types";
import { Container } from "./AboutMain.styled";
import { Img } from "../../UI/Img.styled";

const AboutMain = ({ content }) => {
  return (
    <Container>
      <Img src={content.imgLink} alt={content.imgAlt}></Img>
      <section dangerouslySetInnerHTML={{ __html: content.body }}></section>
    </Container>
  );
};

AboutMain.propTypes = {
  content: PropTypes.shape({
    pageTitle: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    imgLink: PropTypes.node.isRequired,
    imgAlt: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    qualities: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.node.isRequired,
        title: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
      })
    ),
  }),
};

export default AboutMain;
