import React from "react";
import PropTypes from "prop-types";
import AboutQualities from "./AboutQualities/AboutQualities";
import { ServicesPageContainer } from "../ServicesPage/ServicesPage.styled";
import AboutMain from "./AboutMain/AboutMain";

const About = ({ content }) => {
  return (
    <ServicesPageContainer
      title={content.pageTitle}
      subtitle={content.subtitle}
      secondary
    >
      <AboutMain content={content}></AboutMain>
      <AboutQualities content={content.qualities}></AboutQualities>
    </ServicesPageContainer>
  );
};

About.propTypes = {
  content: PropTypes.shape({
    pageTitle: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    imgLink: PropTypes.node.isRequired,
    imgAlt: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    qualities: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.node.isRequired,
        title: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
      })
    ),
  }),
};

export default About;
