import React from "react";

export const sun = (
  <svg
    aria-hidden="true"
    style={{
      msTransform: "rotate(360deg)",
      WebkitTransform: "rotate(360deg)",
    }}
    viewBox="0 0 32 32"
    transform="rotate(360)"
    width="51"
    height="61"
  >
    <path
      fill="none"
      stroke="#1C2F3E"
      strokeWidth="1"
      d="M16 12.005a4 4 0 11-4 4 4.005 4.005 0 014-4m0-2a6 6 0 106 6 6 6 0 00-6-6zM5.394 6.813l1.414-1.415 3.506 3.506L8.9 10.318zM2 15.005h5v2H2zm3.394 10.192L8.9 21.691l1.414 1.415-3.506 3.505zM15 25.005h2v5h-2zm6.687-1.899l1.414-1.415 3.506 3.506-1.414 1.414zM25 15.005h5v2h-5zm-3.313-6.101l3.506-3.506 1.414 1.415-3.506 3.505zM15 2.005h2v5h-2z"
    />
  </svg>
);

export const canvas = (
  <svg
    aria-hidden="true"
    style={{
      msTransform: "rotate(360deg)",
      WebkitTransform: "rotate(360deg)",
    }}
    viewBox="0 0 1025 1025"
    transform="rotate(360)"
    width="51"
    height="61"
  >
    <path
      fill="#1C2F3E"
      d="M960.816 768h-166l93 161q14 23 7 48t-30 38.5-48.5 6.5-38.5-30l-131-224h-70v192q0 26-19 45t-45.5 19-45-19-18.5-45V768h-70l-131 224q-13 23-38.5 30t-48.5-6.5-30-38.5 7-48l93-161h-166q-26 0-45-18.5t-19-45.5V128q0-26 19-45t45-19h384q0-26 19-45t45-19 45 19 19 45h384q26 0 45 19t19 45v576q0 27-19 45.5t-45 18.5z"
    />
  </svg>
);

export const graph = (
  <svg
    aria-hidden="true"
    style={{
      msTransform: "rotate(360deg)",
      WebkitTransform: "rotate(360deg)",
    }}
    viewBox="0 0 32 32"
    transform="rotate(360)"
    width="51"
    height="61"
  >
    <path
      fill="none"
      stroke="#1C2F3E"
      strokeWidth="1"
      d="M28.182 10.573c-1.552-.875-3.51.266-3.823.464l-6.714 3.807a2.621 2.621 0 00-3.359.083 2.62 2.62 0 00-.526 3.318c.646 1.089 1.979 1.557 3.167 1.115s1.885-1.672 1.667-2.917l6.74-3.828c.438-.276 1.464-.693 1.938-.427.344.198.542.844.557 1.802h-.01v8.401c0 .786-.417 1.51-1.094 1.901l-9.63 5.563a2.197 2.197 0 01-2.193 0l-9.63-5.563a2.187 2.187 0 01-1.099-1.901v-11.12c0-.786.417-1.51 1.099-1.901l8.714-5.026a2.622 2.622 0 004.437-2.62 2.621 2.621 0 00-5.084.848l-8.99 5.193a4.041 4.041 0 00-2.031 3.505v11.115c0 1.453.771 2.786 2.026 3.51l9.625 5.563a4.051 4.051 0 004.052 0l9.63-5.563a4.063 4.063 0 002.026-3.505v-8.083h.005c.047-1.896-.464-3.151-1.5-3.734z"
    />
  </svg>
);
