import styled from "styled-components";

export const Container = styled.section`
  grid-column: content;
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 1fr;
  justify-items: center;
  margin-bottom: ${(props) => props.theme.sectionSpacing};
  section {
    max-width: 70ch;
  }
  img {
    align-self: start;
  }
  @media screen and (min-width: ${({ theme }) => theme.tabletScreen}) {
    grid-template-columns: 1fr 1fr;
  }
`;
