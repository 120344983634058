import React from "react";
import Layout from "../components/Layout/layout";
import SEO from "../components/seo";
import About from "../components/About/About";
import digitalAgencySvg from "../images/pages/about-digital-agency.svg";
import { sun, canvas, graph } from "../images/icons/about-icons";

const content = {
  pageTitle: "About Serbay Digital",
  subtitle: "Discover Why You Should Choose Serbay Digital for Your Company",
  imgLink: digitalAgencySvg,
  imgAlt: "illustration showing user experience and interface design",
  body:
    "<h2>WE ARE SERBAY DIGITAL. WE’RE HERE TO HELP</h2><p>Serbay Digital is a digital agency dedicated to delivering premium but affordable web and mobile app development and website design services to small businesses and entrepreneurs. We may be based in Vancouver, British Columbia, but we offer our services globally.</p><p>Our goal is simple: develop digital solutions that engage your customers and generate sales. Your success is our success, and you can count on us to make sure we get things done.</p><h2>WHY CHOOSE US</h2><p>Serbay Digital is made up of experts and consultants in web development, digital marketing, and SEO. We started this company because we know so many other digital marketing agencies are charging small businesses exorbitant prices for second-rate digital services.</p><p>Serbay Digital is here to change that by providing cost-effective services that help your business get to the next level. The last thing we want for your company is to run an expensive campaign that yields poor results.</p><p>Here’s why you should choose Serbay Digital:</p><ul><li>Custom web development at a fair price and supreme quality</li><li>Beautiful user-oriented websites and mobile applications</li><li>Latest tech stacks with honest service at a reasonable cost</li><li>Deep understanding of digital marketing as it relates to your business</li></ul><p>Investments in web development and digital marketing are no laughing matter. We are here to utilize our combined knowledge and digital skills, along with the latest trends and tech stacks, to provide only the best quality of services to our clients for the best price.</p><h2>GET IN TOUCH</h2><p>Rest assured, we deliver on our promises. That’s not just because of our expertise, but because we listen carefully to what you and your users truly need. With Serbay Digital, your business can innovate for the future. Let us know more about how we can help by sending us a quick message.</p>",
  qualities: [
    {
      icon: sun,
      title: "We are creative",
      text: "We create beautifully-designed apps and websites",
    },
    {
      icon: graph,
      title: "We are different",
      text: "We offer premium value-for-money services",
    },
    {
      icon: canvas,
      title: "We are passionate",
      text: "We go the extra mile because we love what we do",
    },
  ],
};

const AboutPage = () => (
  <Layout>
    <SEO
      title="Learn More About Serbay Digital and Our Complete Suite of Web Services"
      description=" Affordability is the name of the game here at Serbay Digital. We deliver cost-effective premium web design services, SEO, and mobile app development"
    />
    <About content={content}></About>
  </Layout>
);

export default AboutPage;
