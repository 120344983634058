import React from "react";
import PropTypes from "prop-types";
import { Heading3, Text, Icon } from "../../UI/Typography/Typography.styled";
import { Container, Item } from "./AboutQualities.styled";
import { HR } from "../../UI/HR.styled";

const AboutQualities = ({ content }) => {
  return (
    <Container>
      {content.map((item) => (
        <Item key={item.title}>
          <Icon>{item.icon}</Icon>
          <HR secondary></HR>
          <div>
            <Heading3 secondary center>
              {item.title}
            </Heading3>
            <Text>{item.text}</Text>
          </div>
        </Item>
      ))}
    </Container>
  );
};

AboutQualities.propTypes = {
  qualities: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.node.isRequired,
      title: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    })
  ),
};

export default AboutQualities;
