import styled from "styled-components";

export const Container = styled.ul`
  grid-column: content;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;

export const Item = styled.li`
  margin: 0 1rem;
  flex: 1 0 30%;
  min-width: 250px;
  max-width: 350px;
  padding: 0 2rem;
  /* box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.25); */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  & span {
    display: block;
    width: 2rem;
    height: 2rem;
    path {
      fill: #1c2f3e;
    }
  }
`;
